var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c("gov-label", {
        attrs: { for: _vm.id },
        domProps: { textContent: _vm._s(_vm.label) }
      }),
      _c("duet-date-picker", {
        attrs: {
          identifier: _vm.id,
          value: _vm.value,
          max: _vm.max,
          min: _vm.min,
          required: _vm.required
        },
        domProps: {
          localization: _vm.localisation_uk,
          dateAdapter: { parse: _vm.parseDate, format: _vm.formatDate }
        },
        on: { duetChange: _vm.dateSelected }
      }),
      _vm.dateError
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.dateError) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }